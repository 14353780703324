import React, { useState, useMemo, useEffect } from "react";
import { MaterialReactTable, MRT_ShowHideColumnsButton, MRT_ToggleDensePaddingButton, MRT_ToggleFiltersButton, MRT_ToggleFullScreenButton, MRT_ToggleGlobalFilterButton, useMaterialReactTable } from "material-react-table";
import { Tooltip, IconButton, Box } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { Link } from "react-router";
import Loader from "./Loader";

const Leaderboard = () => {
  const [nodes, setNodes] = useState([]);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100
  });
  const [columnVisibility, setColumnVisibility] = useState({
    startPoint: false,
    endPoint: false,
    correctnessPoint: false,
    startCounter: false,
    endCounter: false,
    correctnessCounter: false,
  });

  const columns = useMemo(() => [
    {
      accessorKey: "miner",
      header: "Miner",
      muiTableHeadCellProps: {
        className: "custom-header-cell", // Apply custom class
      },
      Cell: ({ renderedCellValue, row }) => (
        <div className="miner-link">
          <img onClick={() => window.open(`https://sepolia.arbiscan.io/address/${row.original.miner}`)} src="/link.png" alt="" />
          <Tooltip title={row.original.miner}>
            <Link to={'/nodestats/' + row.original.miner} style={{ cursor: 'pointer' }}>{row.original.miner}</Link>
          </Tooltip>
        </div>
      )
    },
    {
      accessorKey: "globalPingPoint",
      header: 'GlobalPing Point',
      size: 80,
    },
    {
      accessorKey: "ping_counter",
      header: 'Ping Counter',
      size: 80,
    },
    {
      accessorKey: "pingPercent",
      header: 'Ping Percent',
      size: 80,
    },
    {
      accessorKey: "createPoint",
      header: 'Create Point',
      size: 80,
    },
    {
      accessorKey: "createCounter",
      header: 'Create Counter',
      size: 80,
    },
    {
      accessorKey: "createPercent",
      header: 'Create Percent',
      size: 80,
    },
    {
      accessorKey: "preparePoint",
      header: 'Prepare Point',
      size: 80,
    },
    {
      accessorKey: "prepareCounter",
      header: 'Prepare Counter',
      size: 80,
    },
    {
      accessorKey: "preparePercent",
      header: 'Prepare Percent',
      size: 80,
    },
    {
      accessorKey: "startPoint",
      header: 'Start Point',
      size: 80,
    },
    {
      accessorKey: "precommitPoint",
      header: 'Precommit Point',
      size: 80,
    },
    {
      accessorKey: "precommitCounter",
      header: 'Precommit Counter',
      size: 80,
    },
    {
      accessorKey: "precommitPercent",
      header: 'Precommit Percent',
      size: 80,
    },
    {
      accessorKey: "commitPoint",
      header: 'Commit Point',
      size: 80,
    },
    {
      accessorKey: "commitCounter",
      header: 'Commit Counter',
      size: 80,
    },
    {
      accessorKey: "commitPercent",
      header: 'Commit Percent',
      size: 80,
    },
    {
      accessorKey: "requestPoint",
      header: 'Request Point',
      size: 80,
    },
    {
      accessorKey: "requestCounter",
      header: 'Request Counter',
      size: 80,
    },
    {
      accessorKey: "requestPercent",
      header: 'Request Percent',
      size: 80,
    },
    {
      accessorKey: "endPoint",
      header: 'End Point',
      size: 80,
    },
    {
      accessorKey: "correctnessPoint",
      header: 'Correctness Point',
      size: 80,
    },
    {
      accessorKey: "startCounter",
      header: 'Start Counter',
      size: 80,
    },
    {
      accessorKey: "endCounter",
      header: 'End Counter',
      size: 80,
    },
    {
      accessorKey: "correctnessCounter",
      header: 'Correctness Counter',
      size: 80,
    },
    {
      accessorKey: "node_id",
      header: "ID"
    },
  ], []);

  useEffect(() => {
    setLoading(true);

    // const leaderboardPromise = fetch('/leaderboard')
    const leaderboardPromise = fetch('https://lb-be.cortensor.network/leaderboard')
      .then((res) => res.json())
      .then((data) => {
        data.forEach((node, key) => {
          data[key]['pingPercent'] = node.ping_counter > 0 ? Math.floor(node.globalPingPoint / node.ping_counter * 100) + '%' : 'N/A';
          data[key]['requestPercent'] = node.requestCounter > 0 ? Math.floor(node.requestPoint / node.requestCounter * 100) + '%' : 'N/A';
          data[key]['createPercent'] = node.createCounter > 0 ? Math.floor(node.createPoint / node.createCounter * 100) + '%' : 'N/A';
          data[key]['preparePercent'] = node.prepareCounter > 0 ? Math.floor(node.preparePoint / node.prepareCounter * 100) + '%' : 'N/A';
          data[key]['startPercent'] = node.startCounter > 0 ? Math.floor(node.startPoint / node.startCounter * 100) + '%' : 'N/A';
          data[key]['endPercent'] = node.endCounter > 0 ? Math.floor(node.endPoint / node.endCounter * 100) + '%' : 'N/A';
          data[key]['precommitPercent'] = node.precommitCounter > 0 ? Math.floor(node.precommitPoint / node.precommitCounter * 100) + '%' : 'N/A';
          data[key]['commitPercent'] = node.commitCounter > 0 ? Math.floor(node.commitPoint / node.commitCounter * 100) + '%' : 'N/A';
          data[key]['correctnessPercent'] = node.correctnessCounter > 0 ? Math.floor(node.correctnessPoint / node.correctnessCounter * 100) + '%' : 'N/A';
        });
        setNodes(data);
        table.reset();
      })
      .catch(console.error);

    // const statsPromise = fetch('/stats')
    const statsPromise = fetch('https://lb-be.cortensor.network/stats')
      .then((res) => res.json())
      .then((data) => {
        setStats({
          counters: data.total_counters,
          nodes: data.total_nodes,
          points: data.total_points
        });
      })
      .catch(console.error);

    Promise.all([leaderboardPromise, statsPromise]).finally(() => setLoading(false));
  }, []);

  const table = useMaterialReactTable({
    data: nodes,
    columns,
    state: {
      columnVisibility,
      pagination
    },
    initialState: {
      sorting: [
        { id: 'ping_counter', desc: false }, // Sort by 'ping_counter' in ascending order
        { id: 'requestPoint', desc: false }, // Sort by 'requestPoint' in descending order
      ],
      density: 'compact',
    },
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setColumnVisibility,
    isMultiSortEvent: () => true,
    muiTableBodyProps: {
      sx: () => ({
        '& tr:nth-of-type(odd) > td':
        {
          backgroundColor: 'white',
        },
        '& tr:nth-of-type(even) > td':
        {
          backgroundColor: '#efefef',
        },
      }),
    },
    renderTopToolbarCustomActions: () => {
      return (
        <div className="table-stats" style={{ display: 'flex', gap: '12px', paddingLeft: '10px' }}>
          <div>Total nodes: <strong>{stats.nodes || 'N/A'}</strong></div>
          <div>Total counters: <strong>{stats.counters || 'N/A'}</strong></div>
          <div>Total points: <strong>{stats.points || 'N/A'}</strong></div>
          <div>Total percent: <strong>{isNaN(stats.points / stats.counters * 100) ? 'N/A' : (stats.points / stats.counters * 100).toFixed(2) + '%'}</strong></div>
        </div>
      )
    },
    renderToolbarInternalActions: () => {
      return (
        <Box>
          <MRT_ToggleGlobalFilterButton table={table} />
          <Tooltip title="Clear sorting">
            <IconButton onClick={() => table.resetSorting(true)}>
              <Clear />
            </IconButton>
          </Tooltip>
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />
          <MRT_ToggleDensePaddingButton table={table} />
          <MRT_ToggleFullScreenButton table={table} />
        </Box>
      )
    },
  });

  if (loading) {
    return <Loader />
  }

  return (
    <div className="sessions-container">
      <div className="sessions-list">
        <div className="session-item">
          {/* <div className="session-header">
            <h4>Leaderboard</h4>
          </div> */}

          < div className="leaderboard-container" >
            <MaterialReactTable table={table} />
          </div>
        </div>
      </div >

    </div >
  );
}

export default Leaderboard;
import React, { useState, useEffect } from "react";
import { ethers, isAddress } from "ethers";
import { useAccount } from "wagmi";
import { Activity, X, ArrowUpRight, ArrowDownRight } from "lucide-react";
import NodeStatsABI from "../abi/NodeStats.json";
import { PUBLIC_RPC_URL, NODESTATS_ADDRESS } from "../lib/config";
import { useParams, useNavigate, Link } from "react-router";
import Loader from "./Loader";

const NodeStats = () => {
  const { address } = useAccount();
  const [nodeStatsContract, setNodeStatsContract] = useState(null);
  const [nodeStats, setNodeStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { lookupAddress } = useParams();
  const navigate = useNavigate();
  const [query, setQuery] = useState(lookupAddress || "");

  useEffect(() => {
    if (!lookupAddress) {
      const before = sessionStorage.getItem("node");
      if (before && ethers.isAddress(before)) {
        navigate(`/nodestats/${before}`);
      }
    } else sessionStorage.setItem("node", lookupAddress);
  }, [lookupAddress]);

  const handleAddressLookup = () => {
    if (!query) {
      // Prevent navigation and display an error if the lookup field is empty
      setError("Please enter an Ethereum address to look up.");
      return;
    }

    if (!ethers.isAddress(query)) {
      setError("Invalid Ethereum address. Please enter a valid address.");
      return;
    }

    setError("");
    sessionStorage.setItem("node", query);
    navigate("/nodestats/" + query);
  };

  // useEffect(() => {
  //   if (!lookupAddress && address) {
  //     navigate('/nodestats/' + address);
  //   }
  // }, [lookupAddress, address]);

  useEffect(() => {
    if (!lookupAddress || !nodeStatsContract) return;

    if (!ethers.isAddress(lookupAddress)) {
      setError("Invalid Ethereum address");
      return;
    }

    setLoading(true);
    setError("");

    const nodeHash = ethers.keccak256(ethers.getAddress(lookupAddress));
    nodeStatsContract
      .getActivity(nodeHash)
      .then(setNodeStats)
      .catch((error) => {
        console.error("Error looking up address:", error);
        setError(`Failed to lookup address: ${error.message}`);
        setNodeStats(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [lookupAddress, nodeStatsContract]);

  const organizeStats = (nodeStats) => {
    const formatDisplayLabel = (prefix) => {
      if (prefix === "globalPing") {
        return "Global Ping";
      }
      return prefix.charAt(0).toUpperCase() + prefix.slice(1);
    };

    const prefixes = [
      "Request",
      "Create",
      "Prepare",
      "Start",
      "Precommit",
      "Commit",
      "End",
      "Correctness",
      "Ping",
      "globalPing",
    ];
    const organizedStats = prefixes.map((prefix) => {
      const getPropertyKey = (prefix, suffix) => {
        if (prefix === "globalPing") {
          return `globalPing${suffix}`;
        }
        return `${prefix.toLowerCase()}${suffix}`;
      };

      const pointKey = getPropertyKey(prefix, "Point");
      const counterKey = getPropertyKey(prefix, "Counter");

      const pointValue = nodeStats[pointKey];
      const counterValue = nodeStats[counterKey];

      return {
        label: formatDisplayLabel(prefix),
        point: pointValue ? pointValue.toString() : "0",
        counter: counterValue ? counterValue.toString() : "0",
        successRate: calculateSuccessRate(pointValue, counterValue),
      };
    });

    return organizedStats;
  };

  const calculateSuccessRate = (point, counter) => {
    let numericPoint = typeof point === "bigint" ? Number(point) : point;
    let numericCounter =
      typeof counter === "bigint" ? Number(counter) : counter;

    if (
      numericCounter === 0 ||
      numericCounter === "0" ||
      isNaN(numericPoint / numericCounter)
    )
      return null;
    return (numericPoint / numericCounter) * 100;
  };

  useEffect(() => {
    const init = async () => {
      try {
        // Use public client when wallet is not connected
        const provider = new ethers.JsonRpcProvider(PUBLIC_RPC_URL);
        const nodeStats = new ethers.Contract(
          NODESTATS_ADDRESS,
          NodeStatsABI,
          provider
        );
        setNodeStatsContract(nodeStats);
      } catch (error) {
        console.error("Initialization error:", error);
        setError(`Failed to initialize provider: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    init();
  }, []);

  useEffect(() => {
    const fetchNodeStatsOnTabSwitch = async () => {
      if (!address || !lookupAddress) return;
      console.log(address, lookupAddress, !address || !lookupAddress);

      try {
        setLoading(true);
        setError("");

        const statsAddress = lookupAddress || address;
        if (!statsAddress || !nodeStatsContract) return;
        if (!isAddress(statsAddress))
          throw new Error("Invalid Ethereum address");

        const nodeHash = ethers.keccak256(statsAddress);
        const statsData = await nodeStatsContract.getActivity(nodeHash);

        if (statsData) {
          setNodeStats(statsData);
        } else {
          setNodeStats(null);
        }
      } catch (error) {
        console.error("Error fetching stats on tab switch:", error);
        setError(`Failed to fetch stats: ${error.message}`);
        setNodeStats(null);
      } finally {
        setLoading(false);
      }
    };

    fetchNodeStatsOnTabSwitch();
  }, [lookupAddress, address, nodeStatsContract]);

  const handleReset = (e) => {
    e.preventDefault();
    setQuery("");
    sessionStorage.removeItem("node");
    if (address) {
      // Navigate to connected wallet's stats if wallet is connected
      navigate(`/nodestats/${address}`);
    } else {
      // Navigate back to generic lookup page if no wallet is connected
      navigate("/nodestats");
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!lookupAddress) {
    return (
      <div className="empty-state">
        <div className="empty-state-content">
          <Activity className="icon" size={48} />
          <h3>No Wallet Connected</h3>
          <p>Please connect your wallet to view node statistics</p>
          <p>
            or use the lookup feature below to search for a specific address
          </p>
        </div>
        <div className="address-lookup">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Enter address to lookup"
            className="address-input"
          />

          <button onClick={handleAddressLookup} className="lookup-button">
            Lookup
          </button>
        </div>
        {error && (
          <div className="error-message">
            <p>{error}</p>
          </div>
        )}
      </div>
    );
  }

  const statsAddress = lookupAddress || address;

  return (
    <div>
      <div className="address-display address-display-updated">
        <div className="current-address">
          <p>
            Showing stats for:{" "}
            <a
              href={`https://sepolia.arbiscan.io/address/${statsAddress}`}
              target="_blank"
            >
              {statsAddress}
            </a>
          </p>
        </div>
        <div className="address-lookup">
          <div className="address-search">
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => {
                console.log(e.keyCode);
                if (e.keyCode === 13) handleAddressLookup();
              }}
              placeholder="Enter address to lookup"
              className="address-input"
            />
            <X
              onClick={handleReset}
              className="icon"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div
            className="pagination-buttons"
            style={{ flexWrap: "nowrap", marginTop: 0 }}
          >
            <a
              onClick={handleAddressLookup}
              className="pagination-btn btn link-button"
            >
              Look Up
            </a>
            {/* <a onClick={handleReset} className="pagination-btn btn link-button">
              Reset
            </a> */}
            <a
              href={`http://twitter.com/share?text=${encodeURIComponent(
                "#Cortensor Dashboard: Node Stats - "
              )}&url=${encodeURIComponent(window.location.href)}`}
              target="_blank"
              className="pagination-btn btn link-button share-btn"
            >
              <i className="fa-solid fa-share icon-spacing"></i>
              <span>Share</span>
            </a>
          </div>
        </div>
      </div>

      {error && (
        <div className="error-message">
          <p>{error}</p>
        </div>
      )}

      {!nodeStats || Object.keys(nodeStats).length === 0 ? (
        <p className="no-stats-message">
          No statistics available for this address
        </p>
      ) : (
        <div className="node-stats-container">
          {organizeStats(nodeStats).map((stat, idx) => (
            <div key={idx} className="node-stat-row">
              <h4>{stat.label} Metrics</h4>
              <p>
                <strong>Point:</strong> {stat.point}
              </p>
              <p>
                <strong>Counter:</strong> {stat.counter}
              </p>
              <p style={{ display: "flex", alignItems: "center" }}>
                <strong>Success Rate:&nbsp;</strong>{" "}
                <span
                  style={{
                    color:
                      stat.successRate > 0
                        ? "#2fb135"
                        : stat.successRate < 0
                        ? "#d55555"
                        : undefined,
                  }}
                >
                  {stat.successRate ? stat.successRate.toFixed(2) + "%" : "N/A"}
                  {stat.successRate > 0 ? (
                    <ArrowUpRight
                      className="icon"
                      style={{ position: "relative", top: "3px" }}
                    />
                  ) : (
                    stat.successRate < 0 && (
                      <ArrowDownRight
                        className="icon"
                        style={{ position: "relative", top: "3px" }}
                      />
                    )
                  )}
                </span>
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NodeStats;
